import {
   InnerwellEncounterCount,
   InnerwellProductDescriptionItem,
   PaymentCode,
   TierPriceType,
} from '../products';
import { TherapyPlan } from '../therapy-plans';
import { MagentoAttribute } from './magento-attributes-response.dto';
import { CustomAttribute } from './shared';

type OptionIndex = {
   value_index: number;
};

type MagentoConfigurableProductOption = {
   id: number;
   attribute_id: string;
   label: string;
   position: number;
   values: OptionIndex[];
   product_id: number;
};
type CategoryLink = {
   position: number;
   category_id: string;
};

type ExtensionAttributes = {
   website_ids: number[];
   category_links: CategoryLink[];
   configurable_product_options: MagentoConfigurableProductOption[];
   configurable_product_links: number[];
};

type ProductLink = {
   sku: string;
   link_type: string;
   linked_product_sku: string;
   linked_product_type: string;
   position: number;
};

type TierExtensionAttributes = {
   percentage_value: number;
   website_id: number;
};

type TierPrice = {
   customer_group_id: string;
   qty: number;
   value: number;
   extension_attributes: TierExtensionAttributes;
};

export enum MagentoProductStatusEnum {
   enabled = 1,
   disabled = 2,
}

export enum MagentoProductTypeEnum {
   configurable = 'configurable',
   virtual = 'virtual',
   grouped = 'grouped',
   bundle = 'bundled',
}

export enum MagentoProductVisibility {
   NotVisibleIndividually = 1,
   Catalog = 2,
   Search = 3,
   CatalogAndSearch = 4,
}

export enum MagentoMedicalPlanAddOn {
   none = 'none',
   psychotherapist = 'psychoterapist',
}

export enum MagentoPriceLevel {
   FIRST = 'First',
   SECOND = 'Second',
}

export class MagentoProductDto {
   private _customAttributeMap: Map<string, string> = new Map<string, string>();

   constructor(data: Partial<MagentoProductDto>) {
      Object.assign(this, data);
      // Initialize to map for easier access
      this._customAttributeMap.clear();
      if (this.custom_attributes) {
         this.custom_attributes.forEach((ca: CustomAttribute) => {
            this._customAttributeMap.set(ca.attribute_code, ca.value);
         });
      }
   }

   id: number;
   sku: string;
   name: string;
   attribute_set_id: number;
   price: number;
   status: number;
   visibility: MagentoProductVisibility;
   type_id: string;
   created_at: string;
   updated_at: string;
   extension_attributes: ExtensionAttributes;
   product_links: ProductLink[];
   options: [];
   media_gallery_entries: [];
   tier_prices: TierPrice[];
   custom_attributes: CustomAttribute[];

   getTreatmentPrice(): number | null {
      const treatmentPrice = this._customAttributeMap.get('treatment_price');
      return treatmentPrice ? Number.parseFloat(treatmentPrice) : null;
   }

   getDescription(): string {
      return this._customAttributeMap.get('short_description') ?? '';
   }

   getTitle(): string {
      return this._customAttributeMap.get('title') ?? '';
   }

   getSubtitle(): string {
      return this._customAttributeMap.get('subtitle') ?? '';
   }

   getSpecialPrice(): number | null {
      const specialPrice = this._customAttributeMap.get('special_price');
      return specialPrice ? Number.parseFloat(specialPrice) : null;
   }

   getTherapyPlan(magentoAttributes: MagentoAttribute[]): TherapyPlan | null {
      const therapyPlan = this._customAttributeMap.get('therapy_plan');
      if (therapyPlan) {
         // Find correct mapping for the value
         const rawAttribute = magentoAttributes.find(
            (attr) => attr.attribute_code === 'therapy_plan',
         );
         if (rawAttribute && rawAttribute.options) {
            const found = rawAttribute.options.find(
               (opt) => opt.value == therapyPlan,
            );
            return found ? <TherapyPlan>found.label : <TherapyPlan>therapyPlan;
         }
      } else {
         // No therapy plan found
      }
      return null;
   }

   getAllowedPaymentMethods(
      magentoAttributes: MagentoAttribute[],
   ): PaymentCode[] {
      const value = this._customAttributeMap.get('allowed_payment_methods');
      if (value) {
         const allowedPaymentMethodsAttribute = magentoAttributes.find(
            (attr) => attr.attribute_code === 'allowed_payment_methods',
         );

         if (allowedPaymentMethodsAttribute?.options) {
            const getPaymentFreq = (value: string) => {
               const option = allowedPaymentMethodsAttribute?.options.find(
                  (opt) => {
                     return opt.value === value;
                  },
               );
               if (option) {
                  if (option.label === 'Pay In Full') {
                     return PaymentCode.OnceInFull;
                  }
                  if (option.label === 'Monthly') {
                     return PaymentCode.Monthly;
                  }
                  if (option.label === 'Weekly') {
                     return PaymentCode.Weekly;
                  }
                  if (option.label === 'Two Months') {
                     return PaymentCode.TwoMonths;
                  }
                  if (option.label === 'Four Months') {
                     return PaymentCode.FourMonths;
                  }
                  if (option.label === 'Free') {
                     return PaymentCode.Free;
                  }
               }
               return PaymentCode.Unknown;
            };

            return value.split(',').map((v) => {
               return getPaymentFreq(v);
            });
         }
      }

      return [];
   }

   getSlug(): string {
      return this._customAttributeMap.get('slug') ?? '';
   }

   getChecklistItems(): InnerwellProductDescriptionItem[] {
      let items = this._customAttributeMap.get('checklist_items');
      if (items) {
         items = items.replace(/[\r\n]/gm, '').trim();
         return JSON.parse(items);
      }
      return [];
   }

   getDescriptionItems(): InnerwellProductDescriptionItem[] {
      let items = this._customAttributeMap.get('description_items');
      if (items) {
         items = items.replace(/[\r\n]/, '').trim();

         try {
            return JSON.parse(items);
         } catch (e) {
            // Ignoring the error: let's say it isn't that important field.
            return [];
         }
      }
      return [];
   }

   // encounters_count was set up as a text field in Magento which means limit of around 255 characters
   // encounters_count_v2 is textarea and there is no limit (or higher limit)
   // We cannot fit all of our appointments into encounters_count now that we use the appointment bank
   // for program encounters, too
   getEncountersCount(): InnerwellEncounterCount {
      const encountersCountStrV2 = this._customAttributeMap.get(
         'encounters_count_v2',
      );
      if (encountersCountStrV2) {
         try {
            return JSON.parse(encountersCountStrV2);
         } catch (e) {
            // just ignore the error
         }
      }

      const encountersCountStr =
         this._customAttributeMap.get('encounters_count');
      if (encountersCountStr) {
         try {
            return JSON.parse(encountersCountStr);
         } catch (e) {
            // just ignore the error
         }
      }
      return {};
   }

   getTierPrice(tp: TierPrice): number {
      return tp.value;
   }

   getTierPriceDiscount(tp: TierPrice): number {
      if ('percentage_value' in tp.extension_attributes) {
         // Percentage discount tier price -> we have discount amount AND item price
         return tp.extension_attributes.percentage_value;
      } else {
         // Fixed discount tier price -> we have ONLY amount of discount
         return this.price - tp.value;
      }
   }

   getTierPriceType(tp: TierPrice): TierPriceType {
      return 'percentage_value' in tp.extension_attributes
         ? TierPriceType.Percentage
         : TierPriceType.Fixed;
   }

   getWelkinEncounterId(): string | null {
      return this._customAttributeMap.get('welkin_encounter_id') ?? null;
   }

   getDuration(magentoAttributes: MagentoAttribute[]): number | null {
      const duration = this._customAttributeMap.get('duration');

      if (!duration) {
         return null;
      }

      // Find correct mapping for the value
      const rawAttribute = magentoAttributes.find(
         (attr) => attr.attribute_code === 'duration',
      );

      if (!rawAttribute || !rawAttribute.options) {
         return null;
      }

      const found = rawAttribute.options.find((opt) => opt.value == duration);

      if (!found) {
         return null;
      }

      return parseInt(found.label);
   }

   getCategoryIds(): string[] {
      const ids = this._customAttributeMap.get('category_ids');
      return ids ? Array.from(ids) : [];
   }

   getAvailableInStates(): string[] | undefined {
      const ids = this._customAttributeMap.get('available_in_states');
      return ids ? ids.split(',') : undefined;
   }

   getPriceLevel(
      magentoAttributes: MagentoAttribute[],
   ): MagentoPriceLevel | null {
      const priceLevel = this._customAttributeMap.get('price_level');
      if (!priceLevel) {
         return null;
      }
      // Find correct mapping for the value
      const rawAttribute = magentoAttributes.find(
         (attr) => attr.attribute_code === 'price_level',
      );
      if (!rawAttribute || !rawAttribute.options) {
         return null;
      }
      const found = rawAttribute.options.find((opt) => opt.value == priceLevel);

      if (!found) {
         return null;
      }
      return <MagentoPriceLevel>found.label;
   }

   getNumberOfDoses(): number | null {
      return this._customAttributeMap.get('number_of_doses')
         ? Number(this._customAttributeMap.get('number_of_doses'))
         : null;
   }

   getUpsellProductSkus(): string[] {
      return this.product_links
         ?.filter((link) => link.link_type === 'upsell')
         .map((link) => link.linked_product_sku);
   }
}
